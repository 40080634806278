.home {
    h2, h3 {
        padding: 0;

        &:after {
            display: none;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        font-size: 2.5rem;
        line-height: 2.5rem;
        text-transform: uppercase;

        span {
            display: block;
            font-weight: $font-weight-light;
            font-size: 1.875rem;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            border-radius: $border-radius--round;
            z-index: -1;
        }
    }

    .swiper-navigation {
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: $border-radius--round;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        &__line {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            background-color: $color-white;
            width: 1px;
            height: 30px;
            z-index: 11;
        }

        &:hover {
            .swiper-navigation {
                &__line {
                    z-index: -1;
                }
            }
        }
    }

    .swiper-button {
        position: relative;
        margin: auto;
        top: auto;
        width: 40px;
        height: 100%;
        transition: $duration;

        &:after {
            display: none;
        }

        &-prev {
            left: 0;
            border-radius: 50px 0 0 50px;
        }

        &-next {
            right: 0;
            border-radius: 0 50px 50px 0;
        }
    }

    @keyframes circle {
        0% {
            opacity: 1;
        }
        40% {
            opacity: 1;
        }
        100% {
            width: 200%;
            height: 200%;
            opacity: 0;
        }
    }

    .home-search {
        position: absolute;
        top: 315px;
        left: 0;
        z-index: 2;

        > .container {
            display: flex;
            justify-content: center;
        }

        &__container {
            width: 510px;

            .search-container {
                .search-svg {    
                    transition: $duration;
                    width: 40px;
                    height: 40px;
                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }

                .searchInput {
                    border-color: $color-white;
                    background-color: $color-white;
                    box-shadow: $shadow--variant;
                    filter: none;

                    &::placeholder {
                        color: $color-dark;
                    }
                }
            }
        }
    }

    
    //======================================================================================================
    // A la une
    //======================================================================================================
    .access-alu {
        height: 750px;
        height: 750px;
        margin-bottom: 50px;

        > .container {
            position: relative;
            display: flex;
            align-items: flex-end;
            height: 100%;

            &:before {
                content: '';
                position: absolute;
                top: 275px;
                left: 195px;
                width: 590px;
                height: 500px;
                background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/alu.svg) no-repeat;
                background-size: contain;
                z-index: -1;
            }
        }
    } 

    .alu {
        position: absolute;
        width: 970px;
        height: 750px;
        right: -240px;
        top: 0;

        .swiper-container {
            padding: 20px;
            margin: -20px;
        }

        .swiper-navigation {
            top: 630px;
            left: 185px;
            z-index: 5;

            &__line {
                background-color: $color-white;
            }
        }

        .swiper-button {
            background-color: $color-dark;

            &:hover {
                background-color: $color-second;

                svg {
                    path {
                        fill: $color-dark;
                    }
                }
            }

            svg {
                path {
                    fill: $color-white;
                }
            }
        }

        .slideshow {
            height: 100%;
            width: 100%;

            .shape-container {
                width: 970px;
                height: 750px;
            }

            svg,img, video {
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }

            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                bottom: 0;
                width: 370px;
                height: auto;
                padding: 20px 30px;
                background: $color-white;
                box-shadow: $shadow;
                border-radius: 50px;
            }

            &__title {
                margin: 0 auto;
                text-align: center;
                font-size: 1.375rem;
                line-height: 1.5rem;
                font-weight: $font-weight-semibold;
                color: $color-text;
            }
    
            &--video {
                .shape-container {
                    &--video {
                        display: block;
                    }

                    &--img {
                        display: none;
                    }
                }
            }

            // Navigation (arrow)
            &__navigation {
                position: absolute;
            }
        
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        width: 460px;
        padding-bottom: 30px;

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            position: relative;
            width: 140px;
            height: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 25px;
            margin: 25px 0 0;

            &:hover {
                .access {
                    &__label {
                        color: $color-text;
                    }
                }

                &:before {
                    width: 15px;
                    height: 15px;
                }

                &:after {
                    height: 135px;
                }
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                transition: $duration;
            }

            &:before {
                width: 10px;
                height: 10px;
                background-color: $color-second;
                border-radius: $border-radius--round;
                bottom: -6px;
                left: 0;
                right: 0;
                margin: auto;
            }

            &:after {
                width: 100%;
                height: 100px;
                border-radius: 30px;
                box-shadow: $shadow;
                background-color: $color-white;
                bottom: 0;
                z-index: -1;
            }
        }

        &__svg-container {
            width: 65px;
            height: 65px;
        }

        &__label {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            line-height: 1.25rem;
            text-align: center;
            color: $color-text;
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts-kiosque-nl {
        > .container {
            display: flex;
            justify-content: space-between;
        }
    }

    .posts {
        position: relative;
        padding: 50px 0 0;
        display: flex;
        flex-direction: column;
        width: 770px;

        &__title {
            position: absolute;
            z-index: 2;
            top: 0;
            right: -60px;
            margin: 0;
            width: 160px;
            height: 160px;
            align-items: center;

            &::before {
                background-color: $color-fifth;
                width: 100%;
                height: 100%;
            }
        }

        .swiper-navigation {
            top: 360px;
            right: 260px;

            &__line {
                background-color: $color-dark;
            }
        }

        .swiper-button {
            background-color: $color-fifth;

            &:hover {
                background-color: $color-dark;
    
                svg {
                    path {
                        fill: $color-white;
                    }
                }
            }
    
            svg {
                path {
                    fill: $color-dark;
                }
            }
        }

        &__list {
            position: relative;

            &--alu {
                order: 1;

                .posts {
                    &__item {
                        &__container-img {
                            border: 1px solid $color-border;
                            width: 100%;
                            height: 400px;
                        }

                        &__content {
                            position: relative;
                            top: -50px;

                            &:before {
                                content: '';
                                position: absolute;
                                left: -180px;
                                top: -70px;
                                width: 700px;
                                height: 700px;
                                border-radius: $border-radius--round;
                                background-color: $color-white;
                            }
                        }

                        &__title {
                            position: relative;
                            margin: 0 0 10px;
                            font-size: 2.125rem;
                            line-height: 2.5rem;
                            max-width: 385px;
                        }

                        &__intro {
                            position: relative;
                            max-width: 440px;
                            line-height: 1.375rem;
                        }
                    }
                }
            }
            &--all {
                order: 3;
                display: flex;
                justify-content: space-between;
                margin-top: 70px;

                .posts {
                    &__item {
                        width: 370px;

                        &__container-img {
                            box-shadow: $shadow;
                            width: 100%;
                            height: 225px;
                        }

                        &__title {
                            margin: 10px 0;
                            font-size: 1.375rem;
                            line-height: 1.5rem;
                        }
                    }
                }
            }
        }

        &__item {
            &:hover {
                .posts {
                    &__item {
                        &__container-img {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                border-radius: 30px;
                overflow: hidden;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-gray center;
                z-index: 0;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transform: scale(1);
                    transition: $duration;
                }
            }

            &__title {
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                color: $color-text;
            }

            &__content {
                position: relative;
                z-index: 1;
            }
        }

        &__template {
            margin-top: -95px;
            order: 2;
            display: flex;
            justify-content: flex-end;

            &__btn {
                margin: 0;
                position: relative;
                z-index: 1;
            }
        }
    }

    //======================================================================================================
    // Kiosque + Newsletter
    //======================================================================================================
    .kiosque-nl {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .kiosque {
        position: relative;
        width: 270px;
        padding-top: 180px;

        &:before {
            content: '';
            position: absolute;
            top: 285px;
            right: -130px;
            width: 550px;
            height: 625px;
            background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/kiosque.svg) no-repeat;
            background-size: contain;
            z-index: -1;
        }

        &__title {
            position: absolute;
            z-index: 2;
            top: 95px;
            left: 0;
            right: 0;
            margin: auto;
            width: 160px;
            height: 160px;
            align-items: center;
            font-size: 1.75rem;
            line-height: 1.875rem;

            &::before {
                background-color: $color-fourth;
                width: 100%;
                height: 100%;
            }

            span {
                font-size: 1.75rem;
                line-height: 1.875rem;
            }
        }

        .doc {
            &__container-img {
                width: 100%;
                height: 380px;
                box-shadow: $shadow;
                border-radius: 10px;
                overflow: hidden;
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) no-repeat $color-gray center;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &__details {
                text-align: center;

                &__extension {
                    text-transform: uppercase;
                }
            }

            &__buttons {
                display: flex;
                justify-content: center;
                margin: 20px auto;
            }

            &__btn {
                display: flex;
                width: 44px;
                height: 44px;
                margin: 0 10px;
                border-radius: $border-radius--round;
                background-color: $color-fourth;
                overflow: hidden;

                &:hover {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-dark;
                    transition: $duration;
                }
            }
        }

        &__template {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            &__btn {
                margin: 0;
            }
        }
    }

    .nl {
        filter: drop-shadow(0px 5px 35px rgba(40, 40, 40, 0.1));
        display: flex;
        align-items: center;
        margin-top: 75px;

        > svg {
            position: relative;
            border-radius: $border-radius--round;
            height: 100px;
            width: 100px;
            background-color: $color-third;
            margin-right: -40px;
            padding: 20px;
            z-index: 1;
        }

        &__btn {
            position: relative;
            display: flex;
            align-items: center;
            width: 240px;
            height: 240px;
            background-color: $color-white;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            font-family: $font-family--heading;
            font-size: 1.5rem;
            line-height: 1.875rem;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            color: $color-text;
            padding: 0;

            &:hover {
                color: $color-text;

                &:before {
                    border-top-color: $color-third; 
                    border-right-color:  $color-third;
                    border-bottom-color:  $color-third;
                
                    transition:
                      border-top-color 0.15s linear, 
                      border-right-color 0.15s linear 0.10s,
                      border-bottom-color 0.15s linear 0.20s;
                }

                &:after {
                    border-top: 2px solid  $color-third; 
                    border-left-width: 2px; 
                    border-right-width: 2px; 
                    transform: rotate(270deg); 
                    transition:
                      transform 0.4s linear 0s,
                      border-left-width 0s linear 0.35s; 
                }
            }

            span {
                padding: 0 20px 0 60px;
            }

            svg {
                width: 20px;
                height: 20px;
                border-radius: $border-radius--round;
                background-color: $color-third;
                overflow: hidden;
                flex: none;
                position: absolute;
                bottom: 80px;
                right: 60px;
                transition: $duration;
            }

            &::before,
            &::after {
                box-sizing: inherit;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 100%;
            }

            &::before {
                border: 2px solid transparent; 
            }
            
            
            &::after {
                border: 0 solid transparent; 
            }
        }
    }

    //======================================================================================================
    // Evènements
    //======================================================================================================
    .events {
        position: relative;
        padding: 40px 0 100px;

        > .container {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -60px;
                left: 180px;
                width: 585px;
                height: 705px;
                background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/agenda.svg) no-repeat;
                background-size: contain;
                z-index: -1;
            }
        }

        &__title {
            position: absolute;
            z-index: 3;
            top: -60px;
            margin: 0;
            width: 200px;
            height: 200px;
            align-items: center;

            &::before {
                background-color: $color-second;
                width: 100%;
                height: 100%;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 600px;
        }

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            width: 500px;
            height: 185px;

            &:hover {
                .events {
                    &__item {
                        &__title {
                            color: $color-text;
                        }

                        &__date {
                            color: $color-text;

                            &:after {
                                width: calc(100% + 30px);
                                height: calc(100% + 20px);
                                left: -7px;
                            }
                        }

                        &__image-container {
                            &:before {
                                animation: circle .75s;
                            }
                        }
                    }
                }
            }

            &__image-container {
                position: relative;
                width: 185px;
                height: 185px;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                overflow: hidden;
                flex: none;

                &--no-image {
                    background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat $color-gray center;
                    background-size: initial;
                }

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 2;
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    background: rgba(255,255,255,.2);
                    border-radius: 100%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &__infos {
                padding-left: 30px;
            }

            &__title {
                margin: 0;
                font-family: $font-family--heading;
                font-size: 1.75rem;
                line-height: 1.875rem;
                color: $color-text;
            }

            &__date {
                position: relative;
                display: inline-flex;
                align-items: center;
                margin: 0 0 15px;
                font-family: $font-family--heading;
                font-size: 1.875rem;
                line-height: 2.25rem;
                text-transform: lowercase;
                color: $color-text;

                > p {
                    position: relative;
                    z-index: 1;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 10px;
                    height: 10px;
                    border-radius: $border-radius--round;
                    margin-right: 10px;
                    background-color: $color-second;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background-color: $color-second;
                    width: 0;
                    height: 0;
                    border-radius: 50px;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 0;
                    transition: .35s;
                }
            }

            &--0 {
                width: calc(50% + 25px);
                height: 570px;

                .events {
                    &__item {
                        &__image-container {
                            box-shadow: none;
                            border: 1px solid $color-border;
                            width: 570px;
                            height: 570px;
                            position: relative;
                            z-index: 0;
                        }

                        &__infos {
                            position: absolute;
                            padding-left: 0;
                            top: 415px;
                            left: 0;
                            height: 235px;
                            z-index: 1;

                            &:before {
                                content: '';
                                position: absolute;
                                left: -120px;
                                top: -70px;
                                width: 470px;
                                height: 470px;
                                border-radius: $border-radius--round;
                                background-color: $color-white;
                            }
                        }

                        &__title {
                            position: relative;
                            font-size: 2.125rem;
                            line-height: 2.5rem;
                            max-width: 310px;
                        }
                    }
                }
            }

            &--1 {
                margin-top: 70px;
                margin-bottom: 30px;
            }

            &--1,
            &--2 {
                flex: 1;
            }
        }

        &__template {
            display: flex;
            justify-content: flex-end;
            margin-top: -40px;
            position: relative;
            z-index: 2;

            &__btn {
                margin: 0 0 0 30px;
            }
        }
    }

    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .sw {
        padding: 40px 0;
        position: relative;
        z-index: 1;
        
        > .container {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -70px;
                right: -300px;
                width: 550px;
                height: 665px;
                background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/reseaux.svg) no-repeat;
                background-size: contain;
                z-index: -1;
            }
        }

        &__title {
            margin: 0;
        }

        &__container {
            margin: -44px -30px 0;
        }

        .ff-stream {
            height: 527px!important;
            min-height: 0!important;
            padding: 0!important;
            background-color: $color-bg--transparent!important;
            overflow: hidden;
        }
        .ff-filter-holder {
            display: flex;
            justify-content: flex-end;
            margin: 0 15px 35px!important;

            .ff-filter {
                border-radius: $border-radius--round;
                background-color: $color-fifth!important;
                color: $color-text;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;

                &--active,
                &:hover {
                    background-color: $color-dark!important;
                    color: $color-white;

                    i {
                        color: $color-white!important;
                    }
                }

                i {
                    color: $color-text;
                }

                &:before,
                &:after {
                    display: none;
                }

                &.ff-type-all {
                    text-transform: none!important;
                    font-size: $font-size--text-small!important;
                    font-family: $font-family!important;
                    padding: 0!important;
                }
            }

            .ff-search {
                display: none;
            }
        }
        .ff-item {
            filter: drop-shadow(0px 2px 20px rgba(40, 40, 40, 0.1));
            border-radius: 20px;
            overflow: hidden;
            position: relative ;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 190px;
                width: 110%;
                height: 30px;
                background-color: $color-white;
                clip-path: ellipse(50% 90% at bottom);
                z-index: 5;
            }

            .ff-item-bar {
                color: $color-gray!important;
            }
        }

        .picture-item__inner:hover:before {
            display: none;
        }

        .ff-stream-wrapper {
            margin: 0px!important;
            padding: 0!important;
        }

        .ff-item {
            a {
                color: inherit!important;
            }
        }
    }

    //======================================================================================================
    // Partners
    //======================================================================================================
    .partners {
        padding: 40px 0 85px;

        > .container {
            display: flex;
            justify-content: space-between;
        }

        &__header {
            position: relative;
        }

        &__title {
            padding-left: 30px;

            &:before {
                width: 210px;
                height: 210px;
                background-color: $color-fourth;
            }
        }

        .swiper-container {
            padding: 20px;
            margin: -20px;
        }

        .swiper-navigation {
            bottom: 10px;
            left: 30px;
            filter: drop-shadow(1px 1px 40px rgba(14, 53, 49, 0.1));
        }

        .swiper-button {
            background-color: $color-dark;

            &:hover {
                background-color: $color-white;
    
                svg {
                    path {
                        fill: $color-dark;
                    }
                }
            }
        }

        .swiper-slide {
            position: relative;
            width: 170px!important;
            margin-right: 30px;
        }

        &__list {
            width: calc(100% - 200px);
            display: flex;
        }

        &__item {
            display: flex;
            flex-direction: column;

            &:hover {
                .partners {
                    &__item {
                        &__image {
                            box-shadow: $shadow--hover;
                            border: 1px solid $color-fourth;
                        }

                        &__title {
                            color: $color-dark;
                        }
                    }
                }
            }

            &__image {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 170px;
                box-shadow: $shadow;
                border-radius: $border-radius--round;
                overflow: hidden;
                border: 1px solid $color-white;
                transition: $duration;
            }

            &__content {
                margin: 10px auto 0;
            }

            &__title {
                text-align: center;
                font-weight: $font-weight-semibold;
                color: $color-gray;
                line-height: 1.375rem;
                padding: 0 5px;
            }
        }

        &__nav {
            display: none;

            &--visible {
                display: flex;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        .access-alu {
            height: 675px;

            >.container {
                &:before {
                    width: 465px;
                    height: 395px;
                    top: 220px;
                    left: 245px;
                }
            }
        }

        .alu {
            right: -265px;
            width: 775px;
            height: 680px;

            .swiper-navigation {
                top: 492px;
                left: 120px;
            }
        
            .slideshow {
                .shape-container {
                    width: 775px;
                    height: 600px;
                    padding-bottom: 40px;
                    margin-bottom: 40px;
                }

                &__content {
                    width: 300px;
                    bottom: 0;
                    left: 200px;
                    right: auto;
                }
            }
        }

        .home-search {
            top: 255px;

            &__container {
                width: 485px;
            }
        }

        .posts {
            width: 640px;
            padding: 70px 0 0;

            .swiper-navigation {
                top: 283px;
                right: 162px;
            }

            &__list {
                &--alu {
                    .posts {
                        &__item {
                            &__container-img {
                                width: 640px;
                                height: 335px;
                            }

                            &__intro {
                                max-width: 370px;
                            }

                            &__content {
                                top: -42px;
                            }
                        }
                    }
                }

                &--all {
                    .posts {
                        &__item {
                            width: 300px;

                            &__container-img {
                                width: 300px;
                                height: 180px;
                            }
                        }
                    }
                }
            }

            &__template {
                margin-top: -85px;
            }
        }

        .kiosque {
            width: 240px;

            &:before {
                width: 500px;
                height: 570px;
                top: 230px;
                right: -172px;
            }

            .doc {
                &__container-img {
                    height: 338px;
                }
            }

            &__template {
                &__btn {
                    padding: 10px 10px 10px 35px;
                    width: 240px;
                } 
            }
        }

        .nl {
            > svg {
                margin-right: -30px;
            }

            &__btn {
                width: 220px;
                height: 220px;

                span {
                    padding: 0 20px 0 45px;
                }

                svg {
                    bottom: 70px;
                    right: 55px;
                }
            }
        }

        .events {
            padding: 40px 0;

            > .container {
                &:before {
                    width: 425px;
                    height: 515px;
                    left: 155px;
                    top: -50px;
                }
            }

            &__list {
                max-height: 480px;
            }

            &__item {
                width: 445px;

                &__image-container {
                    width: 150px;
                    height: 150px;
                }

                &__infos {
                    padding-left: 20px;
                }

                &--0 {
                    width: 460px;
                    height: auto;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-right: 30px;

                    .events {
                        &__item {
                            &__image-container {
                                width: 460px;
                                height: 460px;
                            }

                            &__infos {
                                position: relative;
                                top: -140px;
                                left: 0;

                                &:before {
                                    left: -140px;
                                }
                            }
                        }
                    }
                }
            }

            &__template {
                margin-top: -30px;
                margin-bottom: 30px;

                &__btn {
                    margin: 0 0 0 20px;
                }
            }
        }

        .sw {
            >.container {
                &:before {
                    width: 472px;
                    height: 569px;
                    right: -248px;
                    top: -55px;
                }
            }

            &__container {
                margin: -44px -20px 0;
            }

            .ff-filter-holder {
                margin: 0 10px 35px!important;
            }

            .ff-stream {
                height: 453px!important;
            }

            .ff-item-cont {
                height: 310px!important;
            }

            .ff-item {
                filter: drop-shadow(0 2px 15px rgba(40,40,40,.1));

                &:before {
                    top: 145px;
                }
            }
        }

        .partners {
            padding: 40px 0 60px;

            .swiper-container {
                padding: 10px;
                margin: -10px;
            }

            .swiper-slide {
                margin-right: 20px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .home-search {
            display: none;
        }

        .access-alu {
            height: auto;
            margin-bottom: 10px;
            
            > .container {
                flex-direction: column-reverse;
                align-items: center;

                &:before {
                    top: 285px;
                    left: -188px;
                }
            }
        }

        .alu {
            position: relative;
            width: 620px;
            height: auto;
            right: auto;

            .swiper-navigation {
                top: 340px;
                left: 33px;
            }
    
            .slideshow {
                &__content {
                    position: relative;
                    top: -30px;
                    width: 380px;
                    bottom: auto;
                    left: auto;
                    right: auto;
                }

                .shape-container {
                    width: 620px;
                    height: 480px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
    
                &--video {
                    .shape-container {
                        &--video {
                            display: none;
                        }

                        &--img {
                            display: block;
                        }
                    }
                }
            }
        }

        .access {
            margin: 0 auto;
        }

        .posts-kiosque-nl {
            >.container {
                flex-direction: column;
            }
        }

        .posts {
            width: 100%;
            padding: 95px 0 0;

            &__title {
                right: 35px;
                top: 0;
            }

            &__list {
                &--alu {
                    .posts {
                        &__item {
                            &__container-img {
                                width: 100%;
                                height: 325px;
                            }
                        }
                    }
                }

                &--all {
                    margin-top: 60px;
                }
            }
        }

        .kiosque-nl {
            margin-top: 75px;
            align-items: flex-start;
        }

        .kiosque {
            display: flex;
            flex-wrap: wrap;
            padding-top: 33px;
            width: 100%;

            &:before {
                width: 444px;
                height: 506px;
                top: 0;
                right: 0;
            }

            &__title {
                top: 0;
                left: 233px;
                right: auto;
            }

            .doc {
                display: flex;    
                align-items: flex-end;
                width: 100%;

                &__container-img {
                    flex: none;
                    width: 289px;
                    height: 407px;
                }

                &__content {
                    flex: 1;
                    padding-bottom: 185px;
                }
            }

            &__template {
                margin-top: -165px;
                padding-left: 330px;
            }
        }

        .nl {
            width: 100%;
            justify-content: flex-end;
            margin-top: -55px;
        }

        .events {
            padding: 120px 0 40px;

            >.container:before {
                width: 322px;
                height: 391px;
                left: 70px;
                top: -20px;
            }

            &__title {
                top: -130px;
            }

            &__list {
                max-height: 460px;
            }

            &__item {
                width: 300px;

                &__image-container {                   
                    &--no-image {
                        background-size: 45%;
                    }
                }

                &__date {
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                    margin: 0 0 5px;

                    &:before {
                        margin-right: 5px;
                    }
                }

                &__infos {
                    padding-left: 10px;
                }

                &__image-container {
                    width: 100px;
                    height: 100px;
                }

                &--0 {
                    flex-direction: column;
                    width: 300px;
                    overflow: initial;

                    &:before {
                        top: -30px;
                    }

                    .events {
                        &__item {
                            &__image-container {
                                width: 300px;
                                height: 300px;

                                &--no-image {
                                    background-size: 30%;
                                }
                            }

                            &__infos {
                                position: relative;
                                left: 0;
                                top: -40px;
                                height: 205px;
                            }
                        }
                    }
                }
            }

            &__template {
                margin-top: 0;
                justify-content: flex-start;

                &__btn {
                    margin: 0 20px 0 0;
                }
            }
        }

        .sw {
            >.container {
                &:before {
                    width: 472px;
                    height: 569px;
                    right: -240px;
                    top: -35px;
                }
            }

            .ff-stream {
                height: 1047px!important;
            }

            .ff-item-cont {
                height: 420px!important;
            }

            .ff-item {
                &:before {
                    top: 205px;
                }
            }
        }

        .partners {
            padding: 40px 0;

            &__title {
                height: 210px;
                margin: 0;
            }

            .swiper-slide {
                width: 130px!important;
            }

            .swiper-navigation {
                bottom: -20px;
            }

            &__list {
                width: calc(100% - 190px);
                padding-top: 40px;
            }

            &__item {
                &__image {
                    height: 130px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .access-alu {           
            > .container {

                &:before {
                    top: 142px;
                    left: -235px;
                }
            }
        }

        .alu {
            width: 406px;
            
            .swiper-navigation {
                top: 228px;
                left: 53px;
            }

            .slideshow {
                .shape-container {
                    width: 406px;
                    height: 314px;
                }

                &__content {
                    top: -15px;
                    width: 300px;
                }
            }
        }

        .access {
            width: 100%;
        }

        .posts {
            padding: 110px 0 0;

            .swiper-navigation {
                top: 115px;
                right: auto;
                left: 0;
            }

            &__title {
                right: -25px;
            }

            &__list {
                &--alu {
                    margin-bottom: 40px;

                    .posts {
                        &__item {
                            &__container-img {
                                height: 180px;
                            }

                            &__content {
                                top: 20px;

                                &:before {
                                    width: 544px;
                                    height: 544px;
                                    top: -45px;
                                    left: -155px;
                                }
                            }
                        }
                    }
                }

                &--all {
                    order: 2;
                    flex-direction: column;
                    margin-top: 0;

                    .posts {
                        &__item {
                            margin-bottom: 30px;
                        }
                    }
                }
            }

            &__template {
                margin-top: 0;
                order: 3;
                justify-content: flex-start;
            }
        }

        .posts-kiosque-nl>.container {
            align-items: center;
        }

        .kiosque-nl {
            align-items: center;
        }

        .kiosque {
            flex-direction: column;
            padding-top: 80px;

            &:before {
                top: 306px;
                right: -55px;
            }

            &__title {
                left: 0;
                right: 0;
            }

            .doc {
                flex-direction: column;
                align-items: center;

                &__content {
                    padding-bottom: 0;
                }
            }

            &__template {
                padding-left: 0;
                margin-top: 20px;
            }
        }

        .nl {
            margin: 63px auto;
            justify-content: center;
        }

        .events {
            >.container:before {
                left: -260px;
                top: 518px;
                z-index: 1;
            }

            &__list {
                max-height: 100%;
            }

            &__item {
                &--0 {
                    margin-right: 0;

                    .events {
                        &__item {
                            &__infos {
                                height: 175px;
                            }
                        }
                    }
                }

                &--1,
                &--2 {
                    margin-top: 0;
                    position: relative;
                    z-index: 2;
                }
            }

            &__template {
                display: block;
                margin-top: 30px;
                position: relative;
                z-index: 2;

                &__btn {
                    margin-top: 20px;
                }
            }
        }

        .sw {
            >.container {
                &:before {
                    top: auto;
                    bottom: 0;
                    right: -196px;
                }
            }
            &__container {
                margin: 0 -20px 0;
                padding-bottom: 75px;
            }

            .ff-stream {
                height: 2036px!important;
            }            

            .ff-filter-holder {
                justify-content: flex-start;
                margin: 23px 5px 35px!important;
            }
        }

        .partners {
            padding: 0px 0 30px;

            >.container {
                flex-wrap: wrap;
            }

            &__header {
                display: flex;
                align-items: center;
            }

            .swiper-navigation {
                position: relative;
                bottom: -10px;
            }

            &__list {
                width: calc(100% - 20px);
                padding-top: 0;
                margin: -25px auto 0;
            }
        }
    }
}
