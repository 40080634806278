.breadcrumb {
    position: relative;
    margin-bottom: 20px;

    ul  {
        display: inline-flex;
        flex-wrap: wrap;
        min-height: 62px;
        background-color: $color-white;
        box-shadow: $shadow;
        padding: 15px 20px;
        border-radius: 50px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 5px 0;
        line-height: 1.3;

        &:after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/pictos/nav_breadcrumb.svg) no-repeat center;
            background-size: contain;
            margin: 0 5px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        a {
            color: $color-text;
            &:hover {
                color: $color-text;
                text-decoration: underline;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .breadcrumb {
        order: 3;
        margin: 0 auto 20px;

        ul {
            text-align: center;
            justify-content: center;
        }
    }
}