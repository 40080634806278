.cover {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 315px;
    min-height: 705px;

    &__image-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 915px;
        height: 705px;
        right: calc(((100% - 1170px) / 2) - 250px); // right 0 - placement right
        top: 0;

        img,
        svg,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        margin-bottom: 60px;
        
        > .container {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: -40px;
                left: 300px;
                width: 390px;
                height: 460px;
                background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/css.svg) no-repeat;
                background-size: contain;
                z-index: -1;
            }
        }
    }

    &__title {
        max-width: 500px;
        margin: 0 0 20px;
        font-size: 2.813rem;
        line-height: 3.125rem;
    }

    &__lead-wrapper {
        max-width: 500px;

        p {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    &__category {
        margin-bottom: 20px;
        max-width: 500px;
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        padding-top: 275px;
        min-height: 500px;

        &__image-wrapper {
            top: -2px;
            width: 735px;
            height: 570px;
            right: calc(((100% - 940px) / 2) - 275px);
        }

        &__title {
            max-width: 450px;
        }

        &__category {
            max-width: 450px;
        }
    }

}
// 960
@media screen and (max-width: $medium) {

    .cover {
        flex-direction: column;
        padding-top: 0;

        &__image-wrapper {
            position: relative;
            width: 100%;
            height: 410px;
            left: 50%;
            transform: translateX(-50%);
            margin: auto;
            order: 1;
        }

        &__informations {
            >.container {
                &:before {
                    top: -220px;
                    left: -180px;
                }
            }

            order: 2;
            text-align: center;
            margin: 20px auto 40px;
        }

        &__title,
        &__lead-wrapper,
        &__category {
            max-width: 100%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            height: auto;

            svg {
                max-height: 314px;
            }
        }
    }

}
