.footer {
    padding-top: 65px;
    
    &__infos {
        position: relative;
        display: flex;

        &:before {
            content: '';
            position: absolute;
            top: -90px;
            left: -300px;
            width: 685px;
            height: 565px;
            background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/shapes/footer.svg) no-repeat;
            background-size: contain;
            z-index: -1;
        }

        &__logo {
            margin: 50px 160px 0 0;
            display: flex;
            width: 200px;
            height: 167px;
            flex: none;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &__address {
            padding-left: 40px;
            margin-right: 60px;
        }

        &__hours {
            flex: none;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            line-height: 1.5rem;
            margin: 0 0 15px;
        }

        &__text {
            line-height: 1.375rem;
        }

        &__phone {
            position: relative;
            display: inline-flex;
            margin: 20px 0 25px;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;

            &:hover {
                color: $color-link;

                &:after {
                    width: 100%;
                }

                svg {
                    fill: $color-link;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 2px;
                background-color: $color-main;
                margin-left: 5px;
                transition: $duration;
            }

            svg {
                width: 26px;
                height: 26px;
            }
        }

        &__contact {
            height: 70px;
            width: 310px;
            background-color: $color-third;
            margin: 0 0 0 -40px;
            padding: 0 30px 0 15px;
            color: $color-text;
            font-size: 1.25rem;
            transition: all .5s ease;

            &:after {
                background-color: $color-third;
                width: 40px;
                height: 40px;
                left: 15px;
            }

            &:before {
                display: none;
            }

            &:hover {
                color: $color-white;

                &:after {
                    left: -20px;
                    background-color: $color-dark;
                }

                svg {
                    background-color: $color-white;
                }
            }

            svg {
                height: 40px;
                width: 40px;
                border-radius: $border-radius--round;
                background-color: $color-dark;
                fill: $color-white;
                margin-right: 20px;
                transition: all .5s ease;
            }
        }
    }

    &__menu {
        padding: 20px 0 15px 400px;
        height: 80px;
        display: flex;
        align-items: center;
        box-sizing: content-box;

        &__item {
            margin-right: 30px;

            a {
                color: $color-text;
                font-size: $font-size--text-small;
            }

            &:last-child {
                margin-right: 0;
            }

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 1px;
                background-color: $color-dark;
                transition: $duration;
            }

            &:hover {
                a {
                    color: $color-text;
                }

                &:after {
                    width: 100%;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            &:before {
                width: 552px;
                height: 455px;
                left: -262px;
                top: -55px;
            }

            &__logo {
                width: 180px;
                height: 150px;
                margin: 60px 65px 0 0;
            }

            &__address {
                margin-right: 30px;
            }

            &__contact {
                width: 305px;
                padding: 0 25px 0 15px;
            }
        }

        &__menu {
            padding: 20px 0 15px 282px;

            &__item {
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            flex-direction: column;
            align-items: center;
            text-align: center;

            &:before {
                width: 522px;
                height: 431px;
                top: 120px;
                left: -320px;
            }

            &__logo {
                margin: 0 auto 45px;
            }

            &__phone {
                justify-content: center;
            }

            &__address {
                margin: 0 auto 30px;
                padding-left: 0;
                max-width: 300px;
            }

            &__contact {
                width: 300px;
                padding: 0 20px 0 15px;
                margin: auto;

                svg {
                    margin-right: 15px;
                }
            }
        }

        &__menu {
            height: auto;
            flex-wrap: wrap;
            padding: 25px 0 0;
            justify-content: center;

            &__item {
                margin: 10px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__infos {
            &:before {
                left: -480px;
            }
        }
    }
}