
@keyframes identityLogoHorizontal {
    from {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.header {
    width: 100%;
    height: 85px;
    margin-bottom: -85px;
    z-index: 12;

    > .container {
        position: relative;
        display: flex;
    }

    .identity {
        position: absolute;
        width: 350px;
        height: 130px;
        top: 145px;
        left: 45px;
        display: flex;
        z-index: 1;
        transition: $duration;

        &__logo {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &--vertical {
                display: none;
            }

            &--horizontal { 
                display: flex;
                opacity: 1;
                animation: .5s identityLogoHorizontal;
            }
        }

        &__title {
            font-size: 0;
            line-height: 0;
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 15px;
    height: 85px;

    .tool {
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 0;
    }

    &__profile {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 300px;
        height: 85px;
        border-radius: 0px 0px 50px 50px;
        background-color: $color-second!important;
        color: $color-text;
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        font-size: 1.375rem;
        line-height: 1.75rem;
        margin: 0;
        z-index: 5;
    
        &--position {
            z-index: 2;
        }

        &:hover {
            background-color: $color-second;
            color: $color-text;

            svg:first-child {
                transform: rotate(0);
            }
        }

        svg:first-child {
            fill: $color-text;
        }

        svg:last-child {
            fill: $color-text;
            width: 24px;
            height: 24px;
        } 

        &.tool--open {
            background-color: $color-second;
            color: $color-text;

            svg {
                &:last-child {
                    transform: rotate(180deg);
                }
            }
        }

        &__dropdown {
            position: relative;
        }
    }

    &__accessibility,
    &__search {
        width: 40px;
        height: 40px;
        border-radius: $border-radius--round;
        overflow: hidden;
        z-index: 5;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__accessibility {
        display: flex;
        background-color: $color-dark;
        margin-right: 95px;

        &:hover {
            background-color: $color-third;

            svg {
                fill: $color-dark;
            }

        }

        svg {
            fill: $color-white;
            transition: $duration;
        }
    }

    &__search {
        display: none;
        background-color: $color-third;
        position: absolute;
        right: 45px;
        top: 0;
        bottom: 0;
        margin: auto;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &:hover {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }

        }

        svg {
            fill: $color-dark;
            transition: $duration;
        }
    }
}

.tools-view {
    position: absolute;
    top: 0;
    z-index: 3;

    &__list-dropdown {
        display: flex;
        justify-content: flex-end;

        .profils {
            position: relative;
            display: none;
            width: 300px;
            height: auto;
            padding: 95px 20px 20px;
            background: rgba(28,28,28,.97);
            border-radius: 0 0 30px 30px;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__list {
                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 8px 0;

                    &:hover {
                        a {
                            color: $color-third;

                            &:after {
                                opacity: 1;
                                background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/pictos/nav_droite_yellow.svg) no-repeat center;
                            }
                        }

                        &:after {
                            width: 100%;
                        }
                    }
                    
                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        transition: $duration;
                    }

                    &:before {
                        width: 100%;
                        background-color: $color-white;
                        opacity: .5;
                    }

                    &:after {
                        background-color: $color-third;
                        width: 0;
                    }

                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        color: $color-white;
                        line-height: 1.25;
                        font-weight: $font-weight-bold;
                        transition: all $duration;

                        &:after {
                            content: '';
                            display: inline-block;
                            background: url(/wp-content/themes/chavagnes-en-paillers/assets/src/images/sprite-images/pictos/nav_droite.svg) no-repeat center;
                            width: 30px;
                            height: 30px;
                            opacity: .5;
                            transition: $duration;
                        }
                    }
                }
            }
        }
    }
}


iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}

body:not(.home) {
    .tools {
        &__search {
            display: flex;
        }

        &__accessibility {
            margin-right: 55px;
        }
    }

    .header--fixed {
        .tools__accessibility {
            margin-right: 40px;
        }
    }
}

@keyframes identityLogo {
    from {
        top: 145px;
    }
    20% {
        top: -400px;
    }
    40% {
        z-index: 10;
        width: 35px;
        height: 50px
    }
    to {
        left: 15px;
        top: 0;
        bottom: 0;
    }
}

//======================================================================================================
// Header Fixe
//======================================================================================================
.header--fixed {
    position: fixed;
    margin-bottom: 0;

    .identity {
        left: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 35px;
        height: 50px;
        z-index: 10;
        top: 0;
        animation: .5s identityLogo;
        transition: $duration;

        &__logo {
            &--vertical {
                display: flex;
            }

            &--horizontal {
                display: none;
            }
        }
    }

    .tools {
        &__search {
            display: flex;
            right: 30px;
        }

        &__profile {
            border-radius: 0;

            &--position {
                z-index: 5;
            }
        }

        &__accessibility {
            margin-right: 40px;
        }
    }
}

body.admin-bar {
    .header {
        &--fixed {
            top: 30px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .tools {
        right: 10px;

        &__profile {
            width: 240px;
        }

        &__accessibility {
            margin-right: 80px;
        }

        &__search {
            right: 30px;
        }
    }

    .tools-view__list-dropdown .profils {
        width: 240px;
    }

    body:not(.home) {
        .tools__accessibility {
            margin-right: 40px;
        }
    }

    .header {
        .identity {
            width: 310px;
            height: 115px;
            top: 115px;
            left: 30px;
        }
    }

    .header--fixed {
        .identity {
            top: 0;
            width: 35px;
            height: 50px;
            left: 10px;

        }
        .tools {
            &__search {
                right: 10px;
            }

            &__accessibility {
                margin-right: 20px;
            }
        }
    }

    body:not(.home) {
        .header--fixed {
            .tools__accessibility {
                margin-right: 20px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        position: relative;
        height: 95px;
        margin-bottom: 0;

        > .container {
            position: relative;
            height: 95px;
            z-index: 5;

            &:after {
                content: "";
                display: block;
                position: absolute;
                left: -100%;
                right: 0;
                z-index: 5;
                height: 100%;
                background-color: $color-white;
                box-shadow: $shadow--variant;
                border-radius: 0 0 50px 0;
                transition: all .25s ease-in-out;
            }
        }

        .identity {
            width: 185px;
            height: auto;
            top: 13px;
            left: 10px;
            z-index: 6;
        }
    }

    .tools {
        right: 20px;
        height: 95px;
        z-index: 6;

        &__profile {
            width: 200px;
            height: 45px;
            border-radius: 30px;
            font-size: 1rem;
        }

        &__search {
            display: flex;
        }

        &__accessibility {
            margin-right: 40px;
        }
    }
  
    .tools-view {
        top: 70px;
        width: 640px;
        right: 0;

        &__list-dropdown {
            width: 100%;

            .profils {
                width: 100%;
                padding: 25px 20px 20px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {


    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .header {
        height: 192px;

        > .container {
            height: 192px;
            flex-wrap: wrap;

            &:after {
                right: -100%;
                border-radius: 0;
            }
        }

        .identity {
            position: relative;
            width: auto;
            height: 87px;
            top: 0;
            margin: 23px auto 17px;
            left: 0;

            img {
                height: 100%;
                width: auto;
            }
        }
    }

    .tools {
        height: 45px;
        position: absolute;
        bottom: 20px;
        top: auto;
        right: 10px;

        &__profile {
            width: 150px;
            font-size: $font-size--text-small;

            svg:first-child {
                display: none;
            }
        }

        &__search {
            right: 10px;
        }

        &__accessibility {
            margin-right: 20px;
        }
    }

    .tools-view {
        top: 65px;
        width: 300px;
    }

    body:not(.home) .tools__accessibility {
        margin-right: 20px;
    }
}
