#nav-main {
    position: relative;
    width: 100%;
    padding-left: 0;
    transition: $duration;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        z-index: 5;
        position: relative;
        transition: $duration ease-out;

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: -100%;
            right: 355px;
            z-index: -1;
            height: 100%;
            background-color: $color-white;
            box-shadow:$shadow--variant;
            border-radius: 0px 0px 50px 0px;
            transition: all .25s ease-in-out;
        }
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
                &:after {
                    display: none;
                }
            }
        }

        &:hover,
        &.active {
            .nav-main-link {
                &:before {
                    opacity: 1;
                }
             }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 85px;
            padding: 0 45px 0 0;
            margin: 0 35px 0 0;
            color: $color-text;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: 1.375rem;
            line-height: 1.75rem;
            overflow: hidden;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: $color-third;
                border-radius: $border-radius--round;
            }

            &:before {
                width: 60px;
                height: 60px;
                bottom: -42px;
                left: calc(50% - 35px);
                transform: translateX(-50%);
                opacity: 0;
                transition: $duration;
            }

            &:after {
                top: 50%;
                right: 0;
                width: 10px;
                height: 10px;
                transform: translateY(-50%);
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 0 45px;

            &:before {
                content: '';
                position: absolute;
                background: rgba(28,28,28,1);
                height: calc(100% + 85px);
                top: -85px;
                width: 5000px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
                background: rgba(28,28,28,1);
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    padding: 15px 45px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid $color-white;
                    font-family: $font-family;
                    color: $color-white;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background: $color-third;
                        transition: all $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-white;
                        opacity: .5;
                        transition: all $duration;
                    }

                    &:hover {
                        color: $color-third;
                        padding: 15px 30px 15px 15px;

                        &::before {
                            width: 100%;
                        }
                        &::after {
                            color: $color-third;
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                padding-left: 10px;

                a {
                    display: flex;
                    padding: 15px 0 15px 25px;
                    border-bottom: 0;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }

                    &:after {
                        left: 0;
                    }

                    &:hover {
                        padding: 15px 0 15px 25px;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: $border-radius--round;
    background-color: $color-fifth;

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: -1px;
        width: 20px;
        margin: auto;
        background: $color-dark;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-dark;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}

body:not(.home) {
    #nav-main {
        .menu {
            &__nav {
                &__list {
                    &:after {
                        right: 320px;
                    }
                }
            }
        }
    }
}

.header--fixed {
    #nav-main {

        .nav-main-item {
            .nav-main-link {
                padding: 0 35px 0 0;
                margin: 0 25px 0 0;
            }
        }

        .menu {
            padding-left: 65px;
            
            &__nav {
                &__list {
                    &:after {
                        right: -100%;
                        width: 5000px;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    #nav-main {
        .nav-main-item {
            .nav-main-link {
                padding: 0 25px 0 0;
                margin: 0 15px 0 0;
                font-size: 1.25rem;
                line-height: 1.563rem;
            }
        }

        .menu {
            &__nav {
                &__list {
                    &:after {
                        right: 300px;
                    }
                }
            }
        }
    }

    body:not(.home) {
        #nav-main .menu__nav__list:after {
            right: 250px;
        }
    }

    .header--fixed {
        #nav-main {
            .nav-main-item {
                .nav-main-link {
                    padding: 0 18px 0 0;
                    margin: 0 8px 0 0;
                }
            }

            .menu {
                padding-left: 55px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 95px;
            width: 100%;

            &:after {
                background: rgba(28,28,28,.97);
                height: calc(100% + 95px);
                top: -95px;
                width: 2000px;
            }
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 10px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:after {
                    display: none;
                }

                &:hover,
                &ctive {
                    &:before {
                        display: none;
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {

                .nav-main-link {
                    background-color: $color-third;
                    color: $color-text;
                    border-color: $color-third;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px;
                    box-shadow: none;

                    &:before {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px 10px 10px 20px;
                            height: auto;
                            min-height: 50px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 100px 0 0;
            padding: 0;
            font-size: 0;
            line-height: 0;
            z-index: 6;
            position: relative;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        height: 40px;
        justify-content: flex-start;
        bottom: 10px;

        .menu__nav__list {
            top: 65px;
        }
    }
    
}
